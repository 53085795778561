<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="goTo()">粉丝同步</a-button>
    </div>
    <a-table class="doc-table" rowKey="id" :dataSource="dataSource" :columns="columns" :pagination="Refpagination" @change="onLoadData">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'subscribe_scene'">
          <span v-if="record.subscribe_scene == 'ADD_SCENE_SEARCH'" style="color: #b7eb8f"> 公众号搜索 </span>
          <span v-if="record.subscribe_scene == 'ADD_SCENE_ACCOUNT_MIGRATION'" style="color: #b7eb8f"> 公众号迁移 </span>
          <span v-if="record.subscribe_scene == 'ADD_SCENE_PROFILE_CARD'" style="color: #b7eb8f"> 名片分享 </span>
          <span v-if="record.subscribe_scene == 'ADD_SCENE_QR_CODE'" style="color: #b7eb8f"> 扫描二维码 </span>
          <span v-if="record.subscribe_scene == 'ADD_SCENE_PROFILE_LINK'" style="color: #b7eb8f"> 图文页内名称点击 </span>
          <span v-if="record.subscribe_scene == 'ADD_SCENE_PROFILE_ITEM'" style="color: #b7eb8f"> 图文页右上角菜单 </span>
          <span v-if="record.subscribe_scene == 'ADD_SCENE_PAID'" style="color: #b7eb8f"> 支付后关注 </span>
          <span v-if="record.subscribe_scene == 'ADD_SCENE_OTHERS'" style="color: #ffe58f"> 其他 </span>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import * as Api from "@/views/client/wechatApi";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const formState = reactive({});
    const syncFormState = reactive({
      page: 0,
    });
    const page_index = ref();
    const page_count = ref();
    page_index.value = 0;
    page_count.value = 0;
    const dataSource = ref();
    const Refpagination = ref({});
    const onLoadData = (pagination) => {
      if (typeof pagination !== "undefined") {
        formState.page = pagination.current;
      }
      Api.wechat_list_fans(formState).then((res) => {
        Refpagination.value.current = res.data.fans_list.current_page;
        Refpagination.value.pageSize = res.data.fans_list.per_page;
        Refpagination.value.total = res.data.fans_list.total;
        dataSource.value = res.data.fans_list.data;
      });
    };

    const goTo = () => {
      Api.wechat_sync_fans(syncFormState).then((res) => {
        console.log("wechat_sync_fans");
        console.log(res);

        if (page_index.value == 0) {
          syncFormState.page = 0;
          page_count.value = res.data.page_count;
          message.success("同步中");
        }
        syncFormState.page += 1;
        page_index.value += 1;
        if (page_index.value <= page_count.value) {
          goTo();
        } else {
          message.success("同步完成");
          onLoadData();
        }
      });
      // router.push("/client/wechat/material_publish");
    };

    const edit = () => {
      router.push("/client/wechat/material_publish");
    };

    // 初始化获取左侧导航
    onMounted(() => {
      onLoadData();
    });

    return {
      goTo,
      edit,
      dataSource,
      Refpagination,
      onLoadData,
      columns: [
        {
          title: "id",
          dataIndex: "fans_id",
          key: "fans_id",
          width: "10%",
        },
        {
          title: "语言",
          dataIndex: "language",
          key: "language",
          width: "10%",
        },
        {
          title: "openid",
          dataIndex: "openid",
          key: "openid",
          width: "10%",
        },
        {
          title: "unionid",
          dataIndex: "unionid",
          key: "unionid",
          width: "10%",
        },
        {
          title: "粉丝来源",
          dataIndex: "subscribe_scene",
          key: "subscribe_scene",
          width: "10%",
        },
        {
          title: "关注时间",
          dataIndex: "subscribe_time",
          key: "subscribe_time",
          width: "20%",
        },
        //   {
        //     title: "操作",
        //     dataIndex: "action",
        //     key: "action",
        //     width: "10%",
        //   },
      ],
    };
  },
});
</script>